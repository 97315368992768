import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const CampbellLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Kevin Campbell"
    institution="Dr. Kevin Campbell"
    referralCode="CAMPBELL"
    physicianURL="https://urology.ufl.edu/tag/kevin-campbell-md/"
  />
)

export default CampbellLandingPage
